import { render, staticRenderFns } from "./StudentList.vue?vue&type=template&id=54ab9d93&scoped=true&"
import script from "./StudentList.vue?vue&type=script&lang=js&"
export * from "./StudentList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ab9d93",
  null
  
)

export default component.exports