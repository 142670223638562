import { render, staticRenderFns } from "./TeamRegister.vue?vue&type=template&id=065d8e30&scoped=true&"
import script from "./TeamRegister.vue?vue&type=script&lang=js&"
export * from "./TeamRegister.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065d8e30",
  null
  
)

export default component.exports