import { render, staticRenderFns } from "./UserRegister.vue?vue&type=template&id=6645b706&scoped=true&"
import script from "./UserRegister.vue?vue&type=script&lang=js&"
export * from "./UserRegister.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6645b706",
  null
  
)

export default component.exports