<template>
  <LoginVuetify/>
</template>

<script>
import LoginVuetify from "@/components/LoginVuetify.vue";

export default {
  components: { LoginVuetify },
  name: "Login"
};
</script>

<style></style>
