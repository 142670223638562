import { render, staticRenderFns } from "./CourseRegister.vue?vue&type=template&id=3eca6426&scoped=true&"
import script from "./CourseRegister.vue?vue&type=script&lang=js&"
export * from "./CourseRegister.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eca6426",
  null
  
)

export default component.exports