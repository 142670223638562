<template>
    <div>
      <section class="container-fluid my-4">
        <h4 class="my-4">Relação de todas as <strong>Etapas</strong></h4>
  
        <div class="well mb-4">
          <input
            type="text"
            class="form-control"
            placeholder="Filtrar a lista abaixo"
            v-model="search"
          />
        </div>
  
        <table class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th><a href="#" @click="sort($event, 'stage')">Etapa</a></th>
              <th>Descrição</th>
              <th>Criado</th>
              <th width="98px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="text-center"><span class="badge bg-secondary">{{ item.stage }}</span></td>
              <td>{{ item.description | uppercase }}</td>
              <td>{{ item.created_at }}</td>
              <td>
              <ButtonEdit :to="{ name: 'stage-edit', params: { id: item.id } }"/>
              <ButtonDelete @delete="handlerDelete(item.id, 'disciplines')"/>
            </td>
            </tr>
          </tbody>
        </table>
  
        <Pagination :source="pagination" @navigate="navigate" />
      </section>
    </div>
  </template>
    
    <script>
  import api from "@/services";
  import Pagination from "@/components/Pagination.vue";
  import ButtonEdit from "@/components/ButtonEdit.vue";
import ButtonDelete from "@/components/ButtonDelete.vue";
  import { serialize, handlerDelete } from "@/helpers";
  
  export default {
    name: "StageList",
    components: {
      Pagination,
      ButtonEdit,
    ButtonDelete
    },
    data() {
      return {
        sortProperty: "stage",
        sortDirection: 0,
        search: "",
        pagination: {},
        items: [],
        handlerDelete
      };
    },
    methods: {
      sort(ev, property) {
        ev.preventDefault();
        this.sortProperty = property;
  
        this.sortDirection = this.sortDirection ? 0 : 1;
  
        this.getItens();
      },
      navigate(page) {
        this.getItens(page);
      },
      async getItens(page = 1) {
        let query = serialize({
          search: this.search,
          sortDesc: this.sortDirection,
          sortBy: this.sortProperty,
        });
  
        let uri = `/stages?page=${page}` + query;
  
        await api.get(uri).then((res) => {
          this.items = res.data.data;
          this.pagination = res.data;
        });
      },
    },
    mounted() {
      this.getItens();
    },
    watch: {
      async search(ev) {
        this.getItens();
      },
    },
  };
  </script>
    
    <style scoped>
  </style>