<template>
  <div class="my-4">
    <JqueryTable />
  </div>
</template>

<script>
import JqueryTable from "@/components/JqueryTable.vue";

export default {
  name: "generalReportOfStudents",
  components: {
    JqueryTable,
  },
};
</script>

<style scoped>
</style>