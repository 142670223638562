var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-fluid my-4"},[_vm._m(0),_c('form',{ref:"formStudent",on:{"submit":function($event){$event.preventDefault();return _vm.handlerSubmit.apply(null, arguments)}}},[_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Telefone")]),_c('TheMask',{staticClass:"form-control",attrs:{"type":"text","mask":['(##)#####-#####'],"masked":true,"name":"phone"}})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"cpf"}},[_vm._v("Cpf")]),_c('TheMask',{staticClass:"form-control",attrs:{"type":"text","mask":['###.###.###-##'],"name":"cpf"}})],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('button',{staticClass:"btn btn-sm btn-secondary",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" VOLTAR ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"my-4"},[_vm._v("Cadastro de "),_c('strong',[_vm._v("Estudante")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('input',{attrs:{"type":"file","name":"image"}}),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"name"}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"gender"}},[_vm._v("Gênero")]),_c('select',{staticClass:"form-control",attrs:{"name":"gender"}},[_c('option',{attrs:{"disabled":"","selected":"","value":""}},[_vm._v("-- Selecione --")]),_c('option',{attrs:{"value":"F"}},[_vm._v("F")]),_c('option',{attrs:{"value":"M"}},[_vm._v("M")])])]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","name":"email"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"rg"}},[_vm._v("RG")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"rg","name":"rg"}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"expedient_body"}},[_vm._v("Orgão expedidor")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"expedient_body"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"nationality"}},[_vm._v("Nacionalidade")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"nationality"}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"naturalness"}},[_vm._v("Naturalidade")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"naturalness"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"birth_date"}},[_vm._v("Data de nascimento")]),_c('input',{staticClass:"form-control",attrs:{"type":"date","name":"birth_date"}})]),_c('div',{staticClass:"form-group col-md-6"},[_c('label',{attrs:{"for":"name_mother"}},[_vm._v("Nome da mãe")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"name_mother"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group text-center my-2"},[_c('button',{staticClass:"btn btn-success btn-sm mx-2",attrs:{"type":"submit"}},[_vm._v(" SALVAR ")]),_c('button',{staticClass:"btn btn-secondary btn-sm",attrs:{"type":"reset"}},[_vm._v("LIMPAR")])])
}]

export { render, staticRenderFns }